import React, { useContext, useState, useRef } from "react";
import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import axios from "axios";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Context from "./context";
import SubmitButton from "./submit-button";
import SuccessModal from "./success-modal";

const GroupsForm = () => {
  const { data } = useContext(Context);
  const intl = useIntl();
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { register, handleSubmit } = useForm();
  const formEl = useRef(null);

  const onSubmit = (data) => {
    setSuccess("");
    setError("");
    setIsButtonLoading(true);

    axios
      .post(
        `${process.env.GATSBY_SERVER_URL}.netlify/functions/api/contact/groups`,
        {
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phone,
          company: data.company,
          arrival: data.arrival,
          departure: data.departure,
          rooms: data.rooms,
          event: data.event,
          hotel: data.hotel,
          message: data.message,
          object: data.object,
          website: data.website,
          honeypot: data.honeypot,
        }
      )
      .then(function (response) {
        setIsButtonLoading(false);

        if (response.status === 200) {
          formEl.current.reset();
          setSuccess(intl.formatMessage({ id: "form.success" }));
        } else {
          setError(intl.formatMessage({ id: "form.error" }));
        }
      })
      .catch(function (error) {
        console.error(error);
        setError(intl.formatMessage({ id: "form.error" }));
      });
  };

  return (
    <>
      <form ref={formEl} className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-between mb-4">
          <h2 className="paragraph text-yellow m-0">
            {intl.formatMessage({ id: "form.details" })}
          </h2>
          <p className="m-0">
            <small className="label">
              {intl.formatMessage({ id: "form.required" })}
            </small>
          </p>
        </div>
        <input
          type="text"
          style={{ display: "none" }}
          {...register("honeypot")}
        />
        <input type="hidden" value="ace-hotel.com" {...register("website")} />
        <div className="form-field">
          <input
            type="text"
            id="firstName"
            placeholder={intl.formatMessage({ id: "form.first-name" })}
            {...register("firstName", { required: true })}
          />
          <label htmlFor="firstName">
            {intl.formatMessage({ id: "form.first-name" })}
          </label>
        </div>
        <div className="form-field">
          <input
            type="text"
            id="lastName"
            placeholder={intl.formatMessage({ id: "form.name" })}
            {...register("lastName", { required: true })}
          />
          <label htmlFor="lastName">
            {intl.formatMessage({ id: "form.name" })}
          </label>
        </div>
        <div className="form-field">
          <input
            type="text"
            id="company"
            placeholder={intl.formatMessage({ id: "form.company" })}
            {...register("company")}
          />
          <label htmlFor="company">
            {intl.formatMessage({ id: "form.company" })}
          </label>
        </div>
        <div className="form-field">
          <input
            type="email"
            id="email"
            placeholder={intl.formatMessage({ id: "form.email" })}
            {...register("email", { required: true })}
          />
          <label htmlFor="email">
            {intl.formatMessage({ id: "form.email" })}
          </label>
        </div>
        <div className="form-grid">
          <div className="form-field">
            <input
              type="tel"
              id="phone"
              placeholder={intl.formatMessage({ id: "form.phone" })}
              {...register("phone")}
            />
            <label htmlFor="phone">
              {intl.formatMessage({ id: "form.phone" })}
            </label>
          </div>
        </div>
        <h2 className="paragraph text-yellow mt-12 mt-md-16 mb-4">
          {intl.formatMessage({ id: "form.stay" })}
        </h2>
        <div className="form-field">
          <select id="hotel" {...register("hotel", { required: true })}>
            {data.allContentfulHotel.nodes.map((node, i) => {
              const email = node.email.split("@");
              return (
                <option key={i} value={email[0]}>
                  {node.title}
                </option>
              );
            })}
          </select>
          <label htmlFor="hotel">
            {intl.formatMessage({ id: "form.hotel" })}
          </label>
        </div>
        <div className="form-field">
          <input
            type="text"
            id="event"
            placeholder={intl.formatMessage({ id: "form.event" })}
            {...register("event", { required: true })}
          />
          <label htmlFor="event">
            {intl.formatMessage({ id: "form.event" })}
          </label>
        </div>
        <div className="form-grid">
          <div className="form-field">
            <input
              type="date"
              id="arrival"
              defaultValue={new Date().toISOString().slice(0, 10)}
              placeholder={intl.formatMessage({ id: "form.date" })}
              required
              pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
              {...register("arrival", { required: true })}
            />
            <label htmlFor="arrival">
              {intl.formatMessage({ id: "form.arrival" })}
            </label>
          </div>
          <div className="form-field">
            <input
              type="date"
              id="departure"
              defaultValue={new Date().toISOString().slice(0, 10)}
              placeholder={intl.formatMessage({ id: "form.date" })}
              required
              pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
              {...register("departure", { required: true })}
            />
            <label htmlFor="departure">
              {intl.formatMessage({ id: "form.departure" })}
            </label>
          </div>
        </div>
        <div className="form-field">
          <input
            type="number"
            id="rooms"
            placeholder={intl.formatMessage({ id: "form.rooms" })}
            {...register("rooms")}
          />
          <label htmlFor="rooms">
            {intl.formatMessage({ id: "form.rooms" })}
          </label>
        </div>
        <div className="form-field">
          <textarea
            type="textarea"
            id="message"
            placeholder={intl.formatMessage({ id: "form.additional-details" })}
            rows="5"
            cols="40"
            {...register("message")}
          />
          <label htmlFor="message">
            {intl.formatMessage({ id: "form.additional-details" })}
          </label>
        </div>
        <div className="d-flex justify-content-end mt-6">
          <SubmitButton isLoading={isButtonLoading}>
            {intl.formatMessage({ id: "form.send" })}
          </SubmitButton>
        </div>
      </form>
      <SuccessModal success={success} />
      {error && <p className="form-error">{error}</p>}
      {data.contentfulPage.seo_content && (
        <aside className="mt-12 mt-md-20">
          {renderRichText(data.contentfulPage.seo_content, {
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="small">{children}</p>
              ),
            },
          })}
        </aside>
      )}
    </>
  );
};

export default GroupsForm;
